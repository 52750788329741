import axios from 'axios';
import { NqdbEntities } from '../type/types';

class CalcApi {

   putNQDB(entities: NqdbEntities[]) {
      let baseNQDB: string = '';

      if (process.env.REACT_APP_SERVER_ENV === 'production') {
         baseNQDB = "https://hvf4svr71b.execute-api.us-east-1.amazonaws.com";
      } else if (process.env.REACT_APP_SERVER_ENV === 'staging') {
         baseNQDB = "https://sgw41kvxo2.execute-api.us-east-1.amazonaws.com";
      } else {
         baseNQDB = "https://swqie19879.execute-api.us-east-1.amazonaws.com";
      }

      // console.log(baseNQDB);

      const url = baseNQDB + `/prod/v1/statement/NqdbStatement`;
      const headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN")
      };

      // console.log("access token:");
      // console.log(localStorage.getItem("NQ_AZURE_AUTH_TOKEN"));

      return axios.post(url, entities, { headers: headers });
   }

}

export default CalcApi;