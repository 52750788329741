import './message.css';

const Message = (props: any) => {

   const messageArr: any[] = [
      { "title": "NQDB Data saved successfully", "desc": "" },
      {
         "title": "The API call has failed. Please try again",
         "desc": "Something went wrong. Please make sure that empid and statementDate are not blank and try again"
      },
      { "title": "Invalid data", "desc": "Please double check your spreadsheet and try again." },
      {
         "title": "Empty companyId/contractId",
         "desc": "Please select corresponding companyId or contractId before uploading"
      },
      { "title": "Error", "desc": props.custom }
   ];

   return (
      <>
         <div className="pds-modal open" id="pds-modal-decoupled">
            <div className="pds-modal-dialog" role="dialog" aria-labelledby="modal-title">
               <button
                  className="pds-modal-close-dialog pds-modal-close" type="button" onClick={props.closeDialog}
               ></button>
               <h5 id="modal-title" className="modal-title" data-gtm="modal-title">{messageArr[props.msg]['title']}</h5><br />
               <p>
                  <span className="pds-typography-bold">{messageArr[props.msg]['desc']}</span>
                  <br />
               </p>
               <div className="pds-modal-cta">
                  <button
                     className="pds-button pds-button-primary pds-modal-close-dialog" data-gtm="modal-cta" onClick={props.closeDialog}
                  > Ok
                  </button>
               </div>
            </div>
         </div>
      </>
   );
};

export default Message;