import axios from 'axios';

class RestApi {

   baseCalcServiceAPI: string = '';
   tokenCreationAPI: string = '';
   tokenCredential: string = '';

   constructor() {
      if (process.env.REACT_APP_SERVER_ENV === 'production') {
         this.baseCalcServiceAPI = "https://api.principal.com/liability-calc-service-azure-nqdb/";
         this.tokenCreationAPI = "https://accounts.principal.com/oauth2/aus8xm6hson7W0A385d7/v1/token";
         this.tokenCredential = "RWFBNnlKWHM2YThBSU5pTFpWNzlPdFVCQ3NrZ09mTDA6SG9VVGhpRFJlQ1BYRHFrUw==";
      } else {
         this.baseCalcServiceAPI = "https://api.pilot.principal.com/liability-calc-service-azure-nqdb/";
         this.tokenCreationAPI = "https://accounts.staging.principal.com/oauth2/aus4nvp00mFyV36By697/v1/token";
         this.tokenCredential = 'dnlyR2VKM2NDRUptaFc4SXo3ZnpobGRGdTZQdDczaEE6RVhBeDF1djBkTEwyZmJ2Sw==';
      }

   }

   getNqdbRecords() {
      let url = this.baseCalcServiceAPI + `contract/nqdb`;
      let headers = {
         'Authorization': 'Bearer ' + localStorage.getItem("NQ_AZURE_AUTH_TOKEN"),
         'Consumer-Name': 'nqdb-ui',
         'Access-Control-Allow-Origin': '*'
      };

      return axios.get(url, { headers: headers });
   }

   clearTokenCache(): void {
      localStorage.setItem("NQ_AZURE_AUTH_TOKEN", '');
      localStorage.setItem("NQ_AZURE_AUTH_TOKEN_EXPIRES", '');
   }

   setExpirationTime(ttl: number): string {
      let currTime = new Date().getTime();
      let expirationTime = new Date(currTime + ttl * 1000).getTime();

      return expirationTime + '';
   }

}

export default RestApi;
