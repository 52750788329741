import { NqdbEntities } from "../type/types";

export class NqdbEntity extends NqdbEntities {
   constructor();

   constructor(contractId: string, companyId: string, object: any);

   constructor(contractId?: string, companyId?: string, object?: any) {
      super();
      try {
         if (object) {
            if (object["empId"] && object["statementDate"] && object["enterpriseId"]) {
               this.contractId = this.validateEmpty(contractId ?? "0");
               this.companyId = this.validateEmpty(companyId ?? "0");
               this.enterpriseId = this.validateEmpty(object.enterpriseId);
               this.empId = this.validateEmpty(object.empId);
               this.formatEmp = this.validateEmpty(object.formatEmp);
               this.lastName = this.validateEmpty(object.lastName);
               this.firstName = this.validateEmpty(object.firstName);
               this.address1 = this.validateEmpty(object.address1);
               this.address2 = this.validateEmpty(object.address2);
               this.city = this.validateEmpty(object.city);
               this.state = this.validateEmpty(object.state);
               this.zip = this.validateEmpty(object.zip);
               this.participantStatus = this.validateEmpty(object.participantStatus);
               this.nrd = this.validateEmpty(object.nrd);
               this.erd = this.validateEmpty(object.erd);
               this.statementComment1 = this.validateEmpty(object.statementComment1);
               this.statementComment2 = this.validateEmpty(object.statementComment2);
               this.timeOfPayment1 = this.validateEmpty(object.timeOfPayment1, "timeOfPayment1");
               this.statementDate = this.validateEmpty(object.statementDate, "statementDate");
               this.currentFrequency = this.validateEmpty(object.currentFrequency);
               this.currentBenefit = this.validateEmpty(object.currentBenefit);
               this.vestingPercent = this.validateEmpty(object.vestingPercent);
               this.currentVestedBenefit = this.validateEmpty(object.currentVestedBenefit);
               this.currentBenefitStartDate = this.validateEmpty(object.currentBenefitStartDate, "currentBenefitStartDate");
               this.projectedDate = this.validateEmpty(object.projectedDate, "projectedDate");
               this.nrdOrNot = this.validateEmpty(object.nrdOrNot);
               this.projectedFreq = this.validateEmpty(object.projectedFreq);
               this.projectedBenefit = this.validateEmpty(object.projectedBenefit);
               this.projectedVesting = this.validateEmpty(object.projectedVesting);
               this.projectedVested = this.validateEmpty(object.projectedVested);
               this.projectedStartDate = this.validateEmpty(object.projectedStartDate, "projectedStartDate");
               this.accruedForm = this.validateEmpty(object.accruedForm);
               this.definition = this.validateEmpty(object.definition);
               this.projectedForm = this.validateEmpty(object.projectedForm);
            } else {
               throw new Error("File is missing statementDate, or empId, or empId was typed incorrectly. Please check and try again");
            }
         }
      } catch (error) {
         throw (error);
      }
   }

   validateEmpty(field: string, dateName?: string): string {
      if (dateName) {
         if (isNaN(Date.parse(field)) && (field || dateName === "statementDate")) {
            throw new Error("Invalid " + dateName + " entries");
         } else {
            return field === "" ? "0000-00-00" : new Date(field).toISOString().split('T')[0];
         }
      }

      return !field ? "N/A" : field;
   }
}
