import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { SPAAuthProvider } from '@principalfinancialgroup/ciam-spa-auth-provider';
import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import { Layout } from './components/layout/layout';

function App() {

   let clientId = 'b0f3a787-1af3-426d-a5d6-a38735f3eeb3';
   let redirectUri = 'https://nqdb-ui.usis-nq-liability.staging.principalaws.com/';
   let issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0';
   let postLogoutUri = 'https://nqdb-ui.usis-nq-liability.staging.principalaws.com/';

   // console.log(process.env.REACT_APP_SERVER_ENV);

   let scopes = [];
   if (process.env.REACT_APP_SERVER_ENV === 'production') {
      // console.log("in the prod app.tsx")
      clientId = '4867d0a1-68d8-44b0-8292-98c15e79c843';
      redirectUri = 'https://nqdb-ui.usis-nq-liability.prod.principalaws.com/';
      issuer = "https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0";
      postLogoutUri = 'https://nqdb-ui.usis-nq-liability.prod.principalaws.com/';

      scopes.push('api://4867d0a1-68d8-44b0-8292-98c15e79c843/nqdb-ui-prod-scope');
   } else if (process.env.REACT_APP_SERVER_ENV === 'local') {
      // console.log("in the local app.tsx");
      clientId = 'b0f3a787-1af3-426d-a5d6-a38735f3eeb3';
      redirectUri = 'http://localhost:3000';
      issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0';
      postLogoutUri = 'http://localhost:3000';

      scopes.push('api://b0f3a787-1af3-426d-a5d6-a38735f3eeb3/nqdb-db-ui-pilot-scope');
   } else if (process.env.REACT_APP_SERVER_ENV === 'development') {
      // console.log("in the dev app.tsx");
      clientId = 'b0f3a787-1af3-426d-a5d6-a38735f3eeb3';
      redirectUri = 'https://nqdb-ui.usis-nq-liability.dev.principalaws.com/';
      issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0';
      postLogoutUri = 'https://nqdb-ui.usis-nq-liability.dev.principalaws.com/';

      scopes.push('api://b0f3a787-1af3-426d-a5d6-a38735f3eeb3/nqdb-db-ui-pilot-scope');
   } else {
      // console.log("in the staging app.tsx");
      clientId = 'b0f3a787-1af3-426d-a5d6-a38735f3eeb3';
      redirectUri = 'https://nqdb-ui.usis-nq-liability.staging.principalaws.com/';
      issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0';
      postLogoutUri = 'https://nqdb-ui.usis-nq-liability.staging.principalaws.com/';

      scopes.push('api://b0f3a787-1af3-426d-a5d6-a38735f3eeb3/nqdb-db-ui-pilot-scope');
   }

   return (
      <BrowserRouter>
         <Routes>
            <Route path="/*" element={
               <SPAAuthProvider
                  clientId={clientId} redirectUri={redirectUri} issuer={issuer}
                  postLogoutUri={postLogoutUri} scopes={scopes} debug={false}
               >
                  <Provider store={store}>
                     <Layout />
                  </Provider>
               </SPAAuthProvider>
            }/>
            <Route path="/contract/nqdb" element={
               <SPAAuthProvider
                  clientId={clientId} redirectUri={redirectUri} issuer={issuer}
                  postLogoutUri={postLogoutUri} scopes={scopes} debug={false}
               >
                  <Provider store={store}>
                     <Layout />
                  </Provider>
               </SPAAuthProvider>
            }/>
         </Routes>
      </BrowserRouter>
   );
}

export default App;
