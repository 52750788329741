import Header from "../header/header";
import Form from "../form/form";
import "./layout.css";
import Message from '../message/message';
import { useAuth } from 'react-oidc-context';
import { useEffect, useState } from "react";

export const Layout = () => {
   // !!!! 0 succesful, 1 api fail, 2 invalid data, 3 empty company/contract
   // console.log("In the Layout");
   const auth = useAuth();
   // console.log(auth);
   localStorage.setItem("NQ_AZURE_AUTH_TOKEN", auth.user?.access_token!);
   localStorage.setItem("NQ_AZURE_AUTH_TOKEN_EXPIRES", auth.user?.expires_at! + '');

   const [msg, setMsg] = useState(-1);
   const [custom, setCustom] = useState("");
   const [flag, setflag] = useState(false);

   useEffect(() => {
      if (msg !== -1) {
         invokeDialog();
      }
   }, [msg]);

   const invokeDialog = () => {
      setflag(true);
   };

   const closeDialog = () => {
      setMsg(-1);
      setflag(false);
   };

   return (
      <>
         <div>
            <Header />
         </div>
         { flag && <Message msg={msg} custom={custom} closeDialog={closeDialog} /> }
         <div className={`${flag && "blur"}`}>
            <h1 id="formHeader" className="pds-typography-h1">NQDB data upload</h1>
            <Form flag={flag} setflag={setflag} setMsg={setMsg} setCustom={setCustom} />
         </div>
      </>
   );
}
