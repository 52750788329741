export abstract class NqdbEntities {
   protected enterpriseId?: string;
   protected contractId?: string;
   protected companyId?: string;
   protected empId?: string;
   protected formatEmp?: string;
   protected lastName?: string;
   protected firstName?: string;
   protected address1?: string;
   protected address2?: string;
   protected city?: string;
   protected state?: string;
   protected zip?: string;
   protected participantStatus?: string;
   protected nrd?: string;
   protected erd?: string;
   protected statementComment1?: string;
   protected statementComment2?: string;
   protected timeOfPayment1?: string;
   protected statementDate?: string;
   protected currentFrequency?: string;
   protected currentBenefit?: string;
   protected vestingPercent?: string;
   protected currentVestedBenefit?: string;
   protected currentBenefitStartDate?: string;
   protected projectedDate?: string;
   protected nrdOrNot?: string;
   protected projectedFreq?: string;
   protected projectedBenefit?: string;
   protected projectedVesting?: string;
   protected projectedVested?: string;
   protected projectedStartDate?: string;
   protected accruedForm?: string;
   protected definition?: string;
   protected projectedForm?: string;
}