import './form.css';
import * as XLSX from "xlsx";
import FileUpload from '@pds-react/fileUpload';
import Button from '@pds-react/button';
import CalcApi from '../../services/calc.service';
import RestApi from '../../services/rest.service';
import { useEffect, useState } from "react";
import { NqdbEntity } from '../../entities/nqdbEntity';
import CircularProgress from '@mui/material/CircularProgress';

const Form = (props: any) => {
   const [contractId, setContract] = useState("");
   const [companyId, setCompany] = useState("");
   const [result, setResult] = useState<NqdbEntity[]>([]);
   const [loading, setLoading] = useState(false);
   const [nqdbDataList, setDataList] = useState([]);
   const [contractIdList, setContractIdList] = useState<any[]>([]);

   const calc: CalcApi = new CalcApi();
   const rest: RestApi = new RestApi();

   useEffect(() => {
      // setFileDisplay('none')

      const getNqdbData = () => {
         rest.getNqdbRecords().then(
            (response: any) => {
               let dataList = response.data.flatMap((item: any) => (item ? [item] : []));
               // console.log(response.data)
               // console.log(dataList)
               setDataList(dataList);
            }).catch((error: any) => {
               console.log(error);
            }
         );
      };

      getNqdbData();
      // eslint-disable-next-line
   }, []);

   const companyIds = new Set(nqdbDataList?.map(({ companyId }) => companyId));
   const companies = [...companyIds].map(id => ({ key: id, value: id }));

   const setMsg = (num: number) => {
      props.setMsg(num);
   };

   const setCustom = (msg: string) => {
      props.setCustom(msg);
   };

   const setFileDisplay = (sty: string) => {
      document.getElementById("pds-file-upload-input-box-file-list-section")!.style.display = sty;
   };

   const handleSetCompany = (e: any) => {
      const selCompany = e.target.value;
      setCompany(e.target.value);

      let filteredCompanyList: any[] = [];
      // eslint-disable-next-line
      nqdbDataList?.map((x: any) => {
         // eslint-disable-next-line
         if (x.companyId == selCompany) {
            return filteredCompanyList.push(x);
         }
      });

      const contractIds = new Set(filteredCompanyList.map(({ contractId }) => contractId));
      const contracts: any[] = [...contractIds].map(id => ({ key: id, value: id }));

      setContractIdList(contracts);
   };

   const handleSetContract = (e: any) => {
      setContract(e.target.value);
   };

   const processFile = (file: any) => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
         reader.onload = (fileEvent) => {
            const fileContents = fileEvent.target?.result;
            const workbook = XLSX.read(fileContents);
            let fileResult: NqdbEntity[] = [];

            for (let i = 0; i < workbook.SheetNames.length; i++) {
               const jsonList: any[] = XLSX.utils.sheet_to_json(
                  workbook.Sheets[workbook.SheetNames[i]], {
                     raw: false,
                     defval: ""
                  }
               );

               // console.log("list of excel")
               // console.log(jsonList)

               jsonList.forEach((value: any) => {
                  let chosenEnterpriseId = "";
                  // eslint-disable-next-line

                  nqdbDataList?.map((x: any) => {
                     // eslint-disable-next-line
                     if (x.contractId == contractId && x.empId == value["empId"]) {
                        return chosenEnterpriseId = x.enterpriseId;
                     }
                  })
                  
                  value["enterpriseId"] = chosenEnterpriseId;
               })

               try {
                  fileResult.push(...jsonList.map(x => new NqdbEntity(contractId, companyId, x)));
                  // console.log("file contents")
                  // console.log(fileResult)
               } catch (error) {
                  setCustom(error + " at page " + (i + 1));
                  setMsg(4);
                  break;
               }
            };

            setResult(fileResult)
            resolve(fileResult)
         };

         reader.onerror = () => {
            reject('oops, something went wrong with the file reader.');
         };

         reader.readAsArrayBuffer(file);
      });
   };

   const handleSetFile = async (e: any) => {
      if (!companyId || !contractId) {
         setMsg(3);
      } else {
         const f = e.target?.files[0];

         processFile(f);
         setFileDisplay("block");
      }

      e.target.value = null;
   };

   const handleSubmit = (e: any) => {
      if (result.length !== 0) {
         setLoading(true);
         calc.putNQDB(result as NqdbEntity[]).then(
            (response) => {
               // console.log(response)
               if (response.status === 201) {
                  setMsg(0);
               }
               setLoading(false);
            }, (error) => {
               // console.log(error)
               setMsg(1);
               setLoading(false);
            }
         );

         setResult([]);
      }

      setFileDisplay('none');
      setCompany("");
      setContract("");
   };

   const handleCancel = (e: any) => {
      setFileDisplay('none');
      setResult([]);
      setCompany("");
      setContract("");
   };

   return (
      <div>
         { loading && <CircularProgress id="loading" /> }
         <div id="inputFieldContainer">
            <div id="nqBalanceTable">
               <table>
                  <tbody>
                  <tr className="tableRow">
                     <td className="inputFieldTextRequired">
                        Company ID
                     </td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputField">
                        <select
                           className="planDropDownSelect" name="Companies" onChange={handleSetCompany} value={companyId}
                        >
                           <option value="">Select the Company</option>
                           {companies?.map((company, key) => (
                              <option key={key} value={company.key}>
                                 {company.key}
                              </option>
                           ))}
                        </select>
                     </td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputFieldTextRequired">
                        Contract ID
                     </td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputField">
                        <select
                           name="Contracts" onChange={handleSetContract} value={contractId}
                        >
                           <option value="">Select the Contract</option>
                           {contractIdList.map((contract, key) => (
                              <option key={key} value={contract.key}>
                                 {contract.key}
                              </option>
                           ))}
                        </select>
                     </td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputField"></td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputField searchButton">
                        <a
                           href={process.env.PUBLIC_URL + "/NewSampleCompany.xlsx"} download={"NewSampleCompany.xlsx"}
                        > Download sample file </a>
                     </td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputField">
                        <FileUpload
                           accept=".xlsx" dropZoneConfig="box" id="pds-file-upload-input-box" label="" onChange={handleSetFile}
                        />
                     </td>
                  </tr>
                  <tr className="tableRow">
                     <td className="inputField">
                        <Button variant="secondary" onClick={handleSubmit}> Submit </Button>
                        <Button variant="secondary" onClick={handleCancel}> Cancel </Button>
                     </td>
                  </tr>
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
};

export default Form;